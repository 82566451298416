@import "../variables";
@import "../colors";

/* Buttons - Backgroundless */
.p-toolbar .p-button.button-backgroundless {
	margin-right: $panelsPadding;
}
.p-toolbar-group-right .p-button.button-backgroundless {
	margin-left: $panelsPadding;
	margin-right: 0px;
}
.p-button.button-backgroundless {
	@extend %button-backgroundless;
}

%button-backgroundless {
	background: none;
	border: 0px;
	&:not(.p-disabled) {
		color: $textColor;
		span.far {
			color: $textSecondaryColor;
		}
	}
	&.p-disabled {
		color: $defaultColorLighter !important;
		opacity: 1;
	}
	.p-button-icon-left {
		left: 0px !important;
	}
	.p-button-text {
		padding-left: 22px !important;
		padding-right: 0px !important;
	}
	&:enabled:hover,
	&:focus {
		background: none;
		color: $defaultColor;
		span.far {
			color: $defaultColor;
		}
	}
	&:focus {
		border: 0px !important;
		box-shadow: none !important;
	}
	&:active:not(.p-disabled) {
		background: none;
		color: $textSecondaryColor;
		span.far {
			color: $textSecondaryColor;
		}
	}
}

body .p-selectbutton .p-button.p-focus {
	border-color: $inputFocusBorderColor;
}
.p-button.button-link {
	@extend .p-button.button-backgroundless;

	text-align: left;
	&:not(.p-disabled) {
		color: $defaultColor;
	}
	&:enabled:hover {
		text-decoration: underline !important;
	}
	.p-button-text {
		padding-left: 0px !important;
	}
}

.bgdark {
	background: $backgroundDark !important;
}